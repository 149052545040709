import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/AoFxFa9AVCU"
    bibleGroupSrc="https://pdfhost.io/v/3iJ0QdGLM_Bible_Group_Homework_1122pdf.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="Money and Motives - Holiday on its Head" />
  </Layout>
)

export default SermonPost
